import { Stack } from '@fluentui/react';
import { Button, tokens } from '@fluentui/react-components';
import { PersonAdd20Regular } from '@fluentui/react-icons';
import AddContacts from '/src/assets/AddContact.svg';

const EmptyContacts = ({onAddClick}) => {

    const handleAddClick = () => {
        if(onAddClick)
            onAddClick();
    }


    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <img src={AddContacts} alt="Add contacts" />
            <p>You haven't added any contacts yet</p>

            <Stack style={{ flexDirection: 'row', gap: tokens.spacingHorizontalL }}>
                <Button  appearance="primary" icon={<PersonAdd20Regular />} onClick={handleAddClick}>Add contact</Button>
                {/* {categories.map((c) => (
                ))} */}
            </Stack>
        </div>
    );
    }
    export default EmptyContacts;