import { IconButton, ProgressIndicator, Stack, Text } from '@fluentui/react';
import {
	Body1,
	Button,
	Dialog,
	DialogContent,
	DialogSurface,
	DialogTitle,
	Image,
	makeStyles,
	Subtitle2,
} from '@fluentui/react-components';
import { DismissCircle24Filled } from '@fluentui/react-icons';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import ExcelFile from '../../assets/ExcelFile.jpg';
import UploadFile from '../../assets/UploadFile.svg';
import contactsService from '../../services/contactsService';

const schema = yup.object().shape({
	files: yup
		.mixed()
		.test(
			'required',
			'You need to provide a file',
			(value) => value && value.length,
		),
});

const useStyle = makeStyles({
	root: {
		width: '815px',
		maxWidth: '100%',
	},

	container: {
		minHeight: '240px',
	},

	uploadContainer: {
		minHeight: '240px',
		border: '2px dashed #E0E0E0',
		padding: '20px',
		textAlign: 'center',
		marginTop: '20px',
		cursor: 'pointer',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},
});

const ContactUploadPopup = ({ isOpen, onDismiss, category }) => {
	const classes = useStyle();
	const { t } = useTranslation();
	const [uploadedFiles, setUploadedFiles] = useState([]);
	const { register } = useForm({
		resolver: yupResolver(schema),
	});

	useEffect(() => {
		if (!isOpen) setUploadedFiles([]);
	}, [isOpen]);

	useEffect(() => {
		if (!uploadedFiles) return;

		uploadedFiles.forEach((file) => {
			if (file.progress === 0 && file.uploading === false) {
				file.uploading = true;
				uploadFile(file);
			}
		});
	}, [uploadedFiles]);

	const handleFiles = (files) => {
		const newFiles = Array.from(files).map((file) => ({
			file,
			progress: 0,
			uploading: false,
			cancelTokenSource: axios.CancelToken.source(),
		}));

		setUploadedFiles((prevFiles) => [...prevFiles, ...newFiles]);
	};

	const uploadFile = async (fileObj) => {
		const { file, cancelTokenSource } = fileObj;
		try {
			//upload false -> then
			//fazer form data dentro
			const data = await contactsService.uploadContacts(
				category,
				file,
				cancelTokenSource.token,
				(progress) => {
					const progressPercentage = Math.round(progress * 100);

					const updaterFunc = (prevFiles) => {
						return prevFiles.map((f) =>
							f.file === file ? { ...f, progress: progressPercentage } : f,
						);
					};
					setUploadedFiles(updaterFunc);
				},
				() => {
					() => {
						setUploadedFiles((prevFiles) =>
							prevFiles.map((f) =>
								f.file === file ? { ...f, uploading: false } : f,
							),
						);
					};
				},
			);

			// setUploadedFiles((prevFiles) =>
			// 	prevFiles.map((f) =>
			// 		f.file === file ? { ...f, uploading: false } : f,
			// 	),
			// );
		} catch (error) {
			console.log(error);
		}
		// axios
		// 	.post(`//contact-types/${category}/contacts`, formData, {
		// 		cancelToken: cancelTokenSource.token,
		// 		headers: {
		// 			'Content-Type': 'multipart/form-data',
		// 		},
		// 		onUploadProgress: (progressEvent) => {
		// 			const progress = Math.round(
		// 				(progressEvent.loaded * 100) / progressEvent.total,
		// 			);
		// 			setUploadedFiles((prevFiles) =>
		// 				prevFiles.map((f) => (f.file === file ? { ...f, progress } : f)),
		// 			);
		// 		},
		// 	})
		// 	.then(() => {
		// 		setUploadedFiles((prevFiles) =>
		// 			prevFiles.map((f) =>
		// 				f.file === file ? { ...f, uploading: false } : f,
		// 			),
		// 		);
		// 	})
		// 	.catch((error) => {
		// 		if (axios.isCancel(error)) {
		// 			console.log('Upload canceled:', file.name);
		// 		} else {
		// 			console.error('Upload failed:', error);
		// 		}
		// 		setUploadedFiles((prevFiles) =>
		// 			prevFiles.filter((f) => f.file !== file),
		// 		);
		// 	});
	};

	const cancelUpload = (fileObj) => {
		fileObj.cancelTokenSource.cancel();
		setUploadedFiles((prevFiles) =>
			prevFiles.filter((f) => f.file !== fileObj.file),
		);
	};

	return (
		<Dialog open={isOpen} onOpenChange={onDismiss} size="small">
			<DialogSurface className={classes.root}>
				<DialogTitle className={classes.title}>{t('Upload file')}</DialogTitle>
				<DialogContent className={classes.container}>
					<div
						{...register('files')}
						className={classes.uploadContainer}
						onClick={() => document.getElementById('fileInput').click()}
						onDrop={(e) => {
							e.preventDefault();
							handleFiles(e.dataTransfer.files);
						}}
						onDragOver={(e) => e.preventDefault()}
					>
						<Image
							src={UploadFile}
							alt={t('Upload')}
							width={64}
							height={64}
							styles={{ margin: '0 auto' }}
						/>
						<input
							id="fileInput"
							type="file"
							accept=".csv,.xls,.xlsx"
							multiple
							style={{ display: 'none' }}
							onChange={(e) => handleFiles(e.target.files)}
						/>
						<Subtitle2>{t('Select a CSV/Excel file to upload')}</Subtitle2>
						<Body1>{t('or drag and drop it here')}</Body1>
					</div>

					{uploadedFiles && uploadedFiles.length > 0 && (
						<UploadedFileList uploadedFiles={uploadedFiles} />
					)}

					<Button
						icon={<DismissCircle24Filled />}
						size="large"
						onClick={onDismiss}
						appearance="transparent"
						style={{ position: 'absolute', top: 10, right: 10 }}
					/>
				</DialogContent>
			</DialogSurface>
		</Dialog>
	);
};

const UploadedFileList = ({ uploadedFiles }) => {
	const { t } = useTranslation();
	const getFileIcon = (file) => {
		const extension = file.name.split('.').pop().toLowerCase();
		switch (extension) {
			case 'csv':
			case 'xls':
			case 'xlsx':
			default:
				return ExcelFile;
		}
	};
	return (
		<Stack tokens={{ childrenGap: 10 }} style={{ marginTop: 20 }}>
			{uploadedFiles.map((fileObj, index) => (
				<Stack vertical key={index}>
					<Stack horizontal verticalAlign="center">
						<Image
							src={getFileIcon(fileObj.file)}
							alt={fileObj.file.type}
							width={32}
							height={32}
						/>
						<Stack.Item grow styles={{ root: { marginLeft: 10 } }}>
							<Stack direction="vertical">
								<Text>{fileObj.file.name}</Text>

								<Text variant="small">
									{(fileObj.file.size / 1024).toFixed(2)} KB
								</Text>
							</Stack>
						</Stack.Item>
						{fileObj.uploading && (
							<IconButton
								iconProps={{ iconName: 'Cancel' }}
								onClick={() => cancelUpload(fileObj)}
								ariaLabel={t('Cancel upload')}
							/>
						)}
					</Stack>
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
						}}
					>
						<div style={{ width: '90%' }}>
							<ProgressIndicator
								barHeight={5}
								percentComplete={fileObj.progress / 100}
								label={''}
							/>
						</div>
						<span>{fileObj.progress}%</span>
					</div>
				</Stack>
			))}
		</Stack>
	);
};

export default ContactUploadPopup;
