// ContactShortenItem.jsx
import {
	Avatar,
	Button,
	TableCell,
	TableCellActions,
	TableCellLayout,
	TableRow,
	tokens
} from '@fluentui/react-components';
import { Call20Regular, Chat20Regular } from '@fluentui/react-icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { parseISO } from 'date-fns';
import ContactItemActionPopupMenu from '../ContactItemActionPopupMenu';


const ContactItem = ({ contact, onEditClick, onDeleteClick, onWhatsappClick, onChatClick, onCallClick }) => {
	const { t }  = useTranslation('contact');	
	const dob = contact.dob ? parseISO(contact.dob) : '--';

	const getAge = () => {

		if(!contact.dob)
			return '--';

		let age = new Date().getFullYear() - dob.getFullYear();
		const monthDiff = new Date().getMonth() - dob.getMonth();
		if (monthDiff < 0 || (monthDiff === 0 && new Date().getDate() < dob.getDate())) {
			age--;
		}

		return age;
	}

	const handleEdit = () => {
		if(onEditClick) {
			onEditClick(contact);
		}
	};

	const handleDelete = () => {
		if(onDeleteClick) {
			onDeleteClick(contact);
		}
	};

	const handleWhatsappClick = () => {
		if(onWhatsappClick) {
			onWhatsappClick(contact);
		}
	};

	const handleChatClick = () => {
		if(onChatClick) {
			onChatClick(contact);
		}
	};

	const handleCall = () => {
		if(onCallClick) {
			onCallClick(contact);
		}
	};

	return (
		<TableRow >
			<TableCell style={{padding: '5px 8px'}}>
				<TableCellLayout appearance="primary">
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							gap: tokens.spacingHorizontalL,
						}}
					>
						<Avatar name={contact.name } size={32} />
						<span style={{ flexGrow: 1 }}>
							{contact.name}
						</span>
					</div>
				</TableCellLayout>
				<TableCellActions>
					<Button
						appearance="transparent"
						icon={<Chat20Regular />}
						title={t('chat')}
						onClick={handleChatClick} // Implement dialing functionality
					/>
					<Button
						appearance="transparent"
						icon={<Call20Regular />}
						title={t('call')}
						onClick={handleCall} // Implement dialing functionality
					/>
					<ContactItemActionPopupMenu contact={contact} onEdit={handleEdit} onDelete={handleDelete} onWhatsappClick={handleWhatsappClick} />
					
				</TableCellActions>
			</TableCell>
			<TableCell>{getAge()}</TableCell>
			<TableCell>{contact.gender?.title || '--'}</TableCell>
			<TableCell>{contact.email || '--'}</TableCell>
			<TableCell>{contact.phoneNumber || '--'}</TableCell>
		</TableRow>
	);
};

export default ContactItem;
