// ContactsList.jsx
import {
	Table,
	TableBody,
	TableHeader,
	TableHeaderCell,
	TableRow
} from '@fluentui/react-components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ContactItem from './ContactItem';
import DeleteContactPopup from './DeleteContactPopup';

const ContactsList = ({ contacts,  onEditClick, onDeleteClick, onWhatsappClick, onChatClick, onCallClick  }) => {
	const { t }  = useTranslation('contact');
	const [contactToDelete, setContactToDelete] = useState(null);

	const handleDeleteContact = (contact) => {
		setContactToDelete(contact);
	};

	const handleDeleteCancel = () => {
		setContactToDelete(null);
	};


	return (
		<>
		<DeleteContactPopup contact={contactToDelete} onCancel={handleDeleteCancel} onConfirm={onDeleteClick}  />
		<Table style={{borderTop: 'none'}}>
			<TableHeader>
				<TableRow>
					<TableHeaderCell>{ t('name')}</TableHeaderCell>
					<TableHeaderCell style={{width: '8%'}}>{ t('age')}</TableHeaderCell>
					<TableHeaderCell style={{width: '8%'}}>{ t('gender')}</TableHeaderCell>
					<TableHeaderCell style={{width: '20%'}}>{ t('email')}</TableHeaderCell>
					<TableHeaderCell style={{width: '15%'}}>{ t('phone')}</TableHeaderCell>
				</TableRow>
			</TableHeader>
			<TableBody>
				{contacts.map((contact, i) => (
					<ContactItem key={contact.id + i.toString()} contact={contact} 
						onEditClick={onEditClick} onDeleteClick={handleDeleteContact} onWhatsappClick={onWhatsappClick}
						onChatClick={onChatClick} onCallClick={onCallClick} />
				))}
			</TableBody>
		</Table>
		</>
	);
};

export default ContactsList;
