// ContactsList.jsx
import React from 'react';
import ContactShortenItem from './ContactShortenItem';

const ContactsShortenList = ({ contacts }) => {
	return (
		<div>
			{contacts.map((contact) => (
				<ContactShortenItem key={contact.id} contact={contact} />
			))}
		</div>
	);
};

export default ContactsShortenList;
