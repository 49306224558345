import {
	Button,
	makeStyles,
	mergeClasses,
	Subtitle2Stronger,
	tokens,
} from '@fluentui/react-components';
import {
	ArrowUpload16Filled,
	ArrowUpload20Filled,
	People20Filled,
	PersonAdd20Regular,
} from '@fluentui/react-icons';
import { useEffect, useRef, useState } from 'react';
import ContactEditPopup from '../components/Contacts/ContactEditPopup';
import ContactsFilter from '../components/Contacts/ContactsFilter';
import ContactUploadPopup from '../components/Contacts/ContactUploadPopup';
import contactsService from '../services/contactsService';
import useToast from '../theme/toast';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
	root: {
		display: 'flex',
		flexDirection: 'row',
		width: '100%',
	},

	sideBar: {
		flex: '0 0 35%',
		display: 'flex',
		flexDirection: 'column',
		gap: tokens.spacingVerticalM,
		width: '35%',
		maxWidth: '320px',
		backgroundColor: tokens.colorNeutralBackground3,
		borderRight: `1px solid ${tokens.colorNeutralStroke2}`,
		boxShadow: 'inset -8px 0 8px -8px rgba(0, 0, 0, 0.2)',
	},

	titleBar: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		marginBottom: tokens.spacingVerticalM,
		height: '50px',
		justifyContent: 'space-between',
		borderBottom: `1px solid ${tokens.colorNeutralStroke2}`,
		padding: `0 ${tokens.spacingVerticalL}`,
	},

	sideBarItem: {
		padding: '0 16px',
		margin: `${tokens.spacingVerticalXXS} ${tokens.spacingVerticalM}`,
	},

	sideBarItemActive: {
		padding: '0 16px',
		backgroundColor: tokens.colorNeutralBackground1,
	},
});

const Contacts = () => {
	const { t } = useTranslation('contact');
	const toast = useToast();
	const [refresh, setRefresh] = useState(false);
	const [category, setCategory] = useState('');
	const [uploadPopup, setUploadPopup] = useState(false);
	const classes = useStyles();
	const [contact, setContact] = useState(null);
	const [contactTypes, setContactTypes] = useState([]);

	//const categories = ['Business', 'Personal'];
	const categoryLabel = category || 'All contacts';

	useEffect(() => {
		const fetchCategories = async () => {
			try {
				const types = await contactsService.getContactTypes();
				setContactTypes(types);
			} catch (error) {
				toast.error({ message: 'Failed to fetch contact types' });
				console.error('Failed to fetch contact types', error);
			}
		};

		fetchCategories();
	}, []);

	const onAddClick = (c) => {
		setContact({ category: c });
	};

	const onEditClick = (c) => {
		setContact(c);
	};

	const onDeleteClick = async (c) => {
		try {
			await contactsService.deleteContact(c.id);
			refreshHandler();

			toast.success({ message: 'Contact deleted sucessfully' });
		} catch (error) {
			toast.error({ message: 'Failed to save contact' });
		}
	};

	const onWhatsappClick = (c) => {
		console.log('Whatsapp contact', c);
	};

	const onChatClick = (c) => {
		console.log('Chat contact', c);
	};

	const onCallClick = (c) => {
		console.log('Call contact', c);
	};

	const handleContactEdit = (c) => {
		setContact(null);
		refreshHandler();
		console.log('Edit contact', c);
	};

	const refreshHandler = () => {
		setRefresh((r) => !r);
	};

	console.log('category', category);

	return (
		<div className={classes.root}>
			<div className={classes.sideBar}>
				<div className={classes.titleBar}>
					<Subtitle2Stronger>People</Subtitle2Stronger>
					<Button
						appearance="transparent"
						icon={<PersonAdd20Regular />}
						onClick={onAddClick.bind(null, '')}
					></Button>
				</div>

				<div style={{ display: 'flex', flexDirection: 'column' }}>
					<div
						className={mergeClasses(
							classes.sideBarItem,
							category === '' ? classes.sideBarItemActive : '',
						)}
					>
						<Button
							icon={<People20Filled />}
							onClick={() => setCategory('')}
							appearance="transparent"
						>
							All contacts
						</Button>
					</div>
					{contactTypes.map((cat) => (
						<div
							key={cat.name}
							className={mergeClasses(
								classes.sideBarItem,
								category === cat ? classes.sideBarItemActive : '',
							)}
						>
							<Button
								style={{ justifyContent: 'left' }}
								onClick={() => setCategory(cat.name)}
								appearance="transparent"
							>
								{cat.description}
							</Button>
						</div>
					))}
				</div>
			</div>
			<div style={{ flexGrow: 1 }}>
				<div className={classes.titleBar}>
					<Subtitle2Stronger>{categoryLabel}</Subtitle2Stronger>

					{category && (
						<Button
							appearance="outline"
							onClick={() => {
								setUploadPopup(true);
							}}
						>
							{t('uploadContacts')}
						</Button>
					)}
				</div>

				<ContactsFilter
					category={category}
					contactTypes={contactTypes}
					refresh={refresh}
					onAddClick={onAddClick}
					onEditClick={onEditClick}
					onDeleteClick={onDeleteClick}
					onWhatsappClick={onWhatsappClick}
					onChatClick={onChatClick}
					onCallClick={onCallClick}
				/>

				<ContactEditPopup
					contact={contact}
					contactTypes={contactTypes}
					onClose={handleContactEdit}
				/>
			</div>
			<ContactUploadPopup
				isOpen={uploadPopup}
				onDismiss={() => setUploadPopup(false)}
				category={category}
			/>
		</div>
	);
};

export default Contacts;
