import axios from 'axios';

const interceptor = { apiAddress: null, token: null, correlationKey: null };

// Function to set up the interceptor
export const setupInterceptor = (apiAddress, token, tokenParsed, correlationKey) => {

  console.log('setting apiAddress', apiAddress);  
  
  interceptor.apiAddress = apiAddress;
  interceptor.token = token;
  interceptor.tokenParsed= tokenParsed;
  interceptor.correlationKey = correlationKey;
  
};

export const createAxios = (baseUrl) => { 

  console.log('creating axios with apiAddress', interceptor.apiAddress, axiosInstance);
  const axiosInstance = axios.create({ baseURL: baseUrl || interceptor.apiAddress });

  // Function to set up the interceptor
  axiosInstance.interceptors.request.use(
    (config) => {
      // Include the Bearer token in the Authorization header
      if (interceptor.token) {
        config.headers['Authorization'] = `Bearer ${interceptor.token}`;
        config.headers['User-Id'] = interceptor.tokenParsed.sub;
      }

      // Include the correlationKey in the headers
      config.headers['correlationKey'] = interceptor.correlationKey;

      return config;
    },
    (error) => {
      // Handle the error
      return Promise.reject(error);
    },
  );

  return axiosInstance;
}