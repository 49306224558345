import {
	Button,
	Menu,
	MenuItem,
	MenuList,
	MenuPopover,
	MenuTrigger,
} from '@fluentui/react-components';
import {
	Call20Regular,
	Delete20Regular,
	Edit20Regular,
	MoreHorizontal20Regular,
} from '@fluentui/react-icons';

const ContactItemActionPopupMenu = ({
	contact,
	onEdit,
	onDelete,
	onWhatsappClick,
}) => {
	const handleEdit = () => {
		if (onEdit) {
			onEdit(contact);
		}
	};

	const handleWhatsappClick = () => {
		if (onWhatsappClick) {
			onWhatsappClick(contact);
		}
	};

	const handleDelete = () => {
		if (onDelete) {
			onDelete(contact);
		}
	};

	return (
		<Menu>
			<MenuTrigger disableButtonEnhancement>
				<Button
					appearance="transparent"
					icon={<MoreHorizontal20Regular />}
					title="more"
				/>
			</MenuTrigger>

			<MenuPopover>
				<MenuList>
					<MenuItem icon={<Edit20Regular />} onClick={handleEdit}>
						Edit{' '}
					</MenuItem>
					<MenuItem icon={<Call20Regular />} onClick={handleWhatsappClick}>
						Whatsapp
					</MenuItem>
					<MenuItem icon={<Delete20Regular />} onClick={handleDelete}>
						Delete
					</MenuItem>
				</MenuList>
			</MenuPopover>
		</Menu>
	);
};

export default ContactItemActionPopupMenu;
