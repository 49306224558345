import { Toast, ToastBody, Toaster, ToastTitle, toastTitleClassNames, useId, useToastController } from "@fluentui/react-components";
import React from "react";

const useToast = () => {

    const toasterId = React.useContext(ToastContext);
    const { dispatchToast } = useToastController(toasterId);


    const toast = (title, message, type) => {
      
        console.log(`toast: ${title} - ${message} - ${type}`);

        if(title)
        {
          dispatchToast(
              <Toast>
                <ToastTitle>{title}</ToastTitle>
                <ToastBody>{message}</ToastBody>
              </Toast>,
              { intent: type }
            );

        }
        else{

          dispatchToast(
              <Toast>
                <ToastTitle>{message}</ToastTitle>
              </Toast>,
              { intent: type }
            );
        }
    };

    return { 
      success: ({title, message}) => toast(title, message, 'success'), 
      error: ({title, message}) => toast(title, message, 'error'), 
      info: ({title, message}) => toast(title, message, 'info'),
      warning: ({title, message}) => toast(title, message, 'warning') 
    };


};

export const ToastContext = React.createContext('toaster-id');

export const ToastProvider = ({prefix, children}) => {

  const toasterId = useId(prefix);
  console.log("toasterId", toasterId);

  return (
    
    <ToastContext.Provider value={toasterId}>
      {children}
      <Toaster toasterId={toasterId} />
    </ToastContext.Provider>
  );
};



export default useToast;