import {
	Button,
	Menu,
	MenuGroup,
	MenuGroupHeader,
	MenuItemRadio,
	MenuList,
	MenuPopover,
	MenuTrigger,
} from '@fluentui/react-components';
import {
	TextSortAscending16Regular,
	TextSortDescending16Regular,
} from '@fluentui/react-icons';
import { useEffect, useState } from 'react';

const ContactSortPopupMenu = ({
	fields,
	onChange,
	sort,
	direction,
}) => {
	const directions = ['Ascending', 'Descending'];
	const fieldKeys = Object.keys(fields);
	const defaultChecked = { sortBy: [sort], sortDirection: [direction] };

	const handleSortChange = (sort) => {
		onChange(sort, direction);
	};

	const handleSortDirection = (direction) => {
		onChange(sort, direction);
	};

	return (
		<Menu defaultCheckedValues={defaultChecked}>
			<MenuTrigger disableButtonEnhancement>
				<Button
					appearance="transparent"
					icon={
						direction === directions[0] ? (
							<TextSortAscending16Regular />
						) : (
							<TextSortDescending16Regular />
						)
					}
					title="more"
				>
					{fields[sort]}
				</Button>
			</MenuTrigger>

			<MenuPopover style={{ minWidth: '250px' }}>
				<MenuList>
					<MenuGroup title="Sort by" defaultValue={sort}>
						<MenuGroupHeader>Sort by</MenuGroupHeader>
						{fieldKeys.map((item) => (
							<MenuItemRadio
								key={item}
								name="sortBy"
								value={item}
								onClick={handleSortChange.bind(null, item)}
							>
								{fields[item]}
							</MenuItemRadio>
						))}
					</MenuGroup>
					<MenuGroup title="Sort direction" defaultValue={direction}>
						<MenuGroupHeader>Sort direction</MenuGroupHeader>
						{directions.map((item) => (
							<MenuItemRadio
								key={item}
								name="sortDirection"
								value={item}
								onClick={handleSortDirection.bind(null, item)}
							>
								{item}
							</MenuItemRadio>
						))}
					</MenuGroup>
				</MenuList>
			</MenuPopover>
		</Menu>
	);
};

export default ContactSortPopupMenu;
