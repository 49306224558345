import {
	Divider,
	makeStyles,
	Subtitle1,
	Tab,
	TabList,
	tokens,
} from '@fluentui/react-components';
import { useNavigate, useParams } from 'react-router-dom';
import CallTab from '../assets/CallTab.svg';
import ContactsShortenFilter from '../components/ContactsShorten/ContactsShortenFilter';
import { useEffect, useState } from 'react';
import useToast from '../theme/toast';
import contactsService from '../services/contactsService';

const useStyle = makeStyles({
	container: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		gap: tokens.spacingVerticalL,
		padding: tokens.spacingHorizontalL,
		borderRight: `2px solid ${tokens.colorNeutralBackground1Selected}`
	},
});

const Calls = () => {
	const params = useParams();
	const toast = useToast();
	const { category } = params;
	const [tabs, setTabs] = useState([]);
	const navigate = useNavigate();
	const style = useStyle();

	const onTabClick = (e) => {
		navigate(`/calls/${e}`);
	};

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const types = await contactsService.getContactTypes();
                setTabs(types);

				console.log('category', category)
				if(!category)
					navigate(`/calls/${types[0].name}`);

            } catch (error) {
                toast.error({message: 'Failed to fetch contact types'});
            }
        };

        fetchCategories();
    }, []);

	//get
	return (
		<div className={style.container}>
			<div
				style={{
					display: 'flex',
					gap: tokens.spacingHorizontalM,
					alignItems: 'center',
					justifyContent: 'space-between',
				}}
			>
				<div style={{ display: 'flex', gap: tokens.spacingHorizontalS }}>
					<img src={CallTab} width={31} alt="Calls" />
					<Subtitle1>Calls</Subtitle1>
				</div>

				<TabList selectedValue={category}>
					{tabs.map((tab) => (
						<Tab
							key={tab.name}
							onClick={onTabClick.bind(null, tab.name)}
							value={tab.name}
						>
							{tab.description}
						</Tab>
					))}
				</TabList>
			</div>
			<Divider style={{flexGrow: 0}} />

			<div>
				<ContactsShortenFilter category={category} />
			</div>
		</div>
	);
};

export default Calls;
