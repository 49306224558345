import { createDarkTheme, createLightTheme } from "@fluentui/react-components";

// src/theme.js
export const theme = {
  palette: {
    themePrimary: '#6383fa', 
    themeLighterAlt: '#f1f5fd',
    themeLighter: '#d3e0fb',
    themeLight: '#b3c2f8',
    themeTertiary: '#7f9df4',
    themeSecondary: '#4d76f2',
    themeDarkAlt: '#5871d4',
    themeDark: '#4658a0',
    themeDarker: '#2e3f6f',
    neutralLighterAlt: '#f8f8f8',
    neutralLighter: '#f4f4f4',
    neutralLight: '#eaeaea',
    neutralQuaternaryAlt: '#e1e1e1',
    neutralQuaternary: '#d0d0d0',
    neutralTertiaryAlt: '#c8c8c8',
    neutralTertiary: '#c0c0c0',
    neutralSecondary: '#a6a6a6',
    neutralPrimaryAlt: '#6d6d6d',
    neutralPrimary: '#333333',
    neutralDark: '#212121',
    black: '#000000',
    white: '#ffffff'
  },
};


export const useTheme2 = (baseTheme) => {
    return {
        ...baseTheme,
        palette: {
          ...baseTheme.palette,
          themePrimary: '#6383fa',  // Custom primary color
          themeLighterAlt: '#f1f5fd',
          themeLighter: '#d3e0fb',
          themeLight: '#b3c2f8',
          themeTertiary: '#7f9df4',
          themeSecondary: '#4d76f2',
          themeDarkAlt: '#5871d4',
          themeDark: '#4658a0',
          themeDarker: '#2e3f6f',
          neutralLighterAlt: '#f8f8f8',
          neutralLighter: '#f4f4f4',
          neutralLight: '#eaeaea',
          neutralQuaternaryAlt: '#e1e1e1',
          neutralQuaternary: '#d0d0d0',
          neutralTertiaryAlt: '#c8c8c8',
          neutralTertiary: '#c0c0c0',
          neutralSecondary: '#a6a6a6',
          neutralPrimaryAlt: '#6d6d6d',
          neutralPrimary: '#333333',
          neutralDark: '#212121',
          black: '#000000',
          white: '#ffffff'
        },
      };
};


const contactCenter = { 
    10: "#020205",
    20: "#141622",
    30: "#1F233B",
    40: "#282E51",
    50: "#313967",
    60: "#39457F",
    70: "#425298",
    80: "#4A5EB1",
    90: "#536BCB",
    100: "#5C78E5",
    110: "#6986FB",
    120: "#8195FC",
    30: "#97A4FD",
    40: "#ABB4FE",
    150: "#BEC3FF",
    160: "#D0D3FF"
};
//createLightTheme, createDarkTheme
export const useTheme = () => {
    
    const lightTheme = {
        ...createLightTheme(contactCenter), 
     };
     
      const darkTheme = {
        ...createDarkTheme(contactCenter), 
     };
     
     
    darkTheme.colorBrandForeground1 = contactCenter[110];
    darkTheme.colorBrandForeground2 = contactCenter[120];
 
    return {
        lightTheme,
        darkTheme
      };
};