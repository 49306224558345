import { makeStyles, Title3, tokens } from '@fluentui/react-components';
import ContactInfo from '../components/Contact/ContactInfo';
import { useEffect, useState } from 'react';
import contactsService from '../services/contactsService';
import useToast from '../theme/toast';

const useStyles = makeStyles({
	container: {
		padding: tokens.spacingHorizontalXL,
		background: tokens.colorNeutralBackground2,
		display: 'flex',
		width: '100%',
	},
	section: {
		border: '1px solid ' + tokens.colorNeutralStroke1,
		borderRadius: tokens.borderRadiusMedium,
		display: 'flex',
		width: '100%',
	},
});

const ContactInCall = () => {
	const styles = useStyles();
	const toast = useToast();
	const [userInfo, setUserInfo] = useState();
	const [patient, setPatient] = useState();

	useEffect(() => {
		const fetch = async () => {
			try {
				const data = await contactsService.activeCall();
				setUserInfo(data);
        console.log('userinfo', data)
			} catch (error) {
				toast.error({ message: 'Could not get active call info' });
			}
		};

		fetch();
	}, []);

	useEffect(() => {
		if (!userInfo) return;

		const fetch = async () => {
			try {
				const data = await contactsService.getPatient(userInfo.callingParty.id);
				setPatient(data);
			} catch (error) {
        		console.log('error', error);
				toast.error({ message: 'Could not get patient info' });
			}
		};

		fetch();
	}, [userInfo]);

	return (
		<div className={styles.container}>
			<div className={styles.section}>
				{userInfo && <ContactInfo user={userInfo} patient={patient} />}
				{!userInfo && <Title3>No active call</Title3>}
			</div>
		</div>
	);
};

export default ContactInCall;
