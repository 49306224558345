import { createAxios } from '../interceptor/axios.interceptor';

class ContactsService {
	constructor() {
		//this.api = createAxios();
	}

	get api() {
		if (!this._api) {
			this._api = createAxios();
		}
		return this._api;
	}

	// Example methods based on Swagger paths
	formatDirection = (unformattedDirection) => {
		const allowedDirections = ['asc', 'desc'];
		let formattedDirection = allowedDirections[0];

		if (!unformattedDirection) return formattedDirection;

		allowedDirections.forEach((direction) => {
			if (unformattedDirection.toLowerCase().includes(direction)) {
				formattedDirection = direction;
			}
		});
		return formattedDirection;
	};

	buildContactsParam = (type, sortBy, sortDirection) => {
		const params = {};

		if(sortBy ) {
			const formattedDirection = this.formatDirection(sortDirection);
			const sort = `${sortBy}|${formattedDirection}`;
			params.sort = sort;
		}
		if (type) params.type = type;

		return { params };
	};

	async getContacts(type = undefined, sortBy, sortDirection) {
		const params = this.buildContactsParam(type, sortBy, sortDirection);
		try {
			const response = await this.api.get('/contacts', params);
			return response.data;
		} catch (error) {
			console.error(`Error fetching contacts`, error);
			throw error;
		}
	}

	async getContactById(contactId) {
		try {
			const response = await this.api.get(`/contacts/${contactId}`);
			return response.data;
		} catch (error) {
			console.error(`Error fetching contact with ID ${contactId}:`, error);
			throw error;
		}
	}

	async createContact(contactData) {
		try {
			const response = await this.api.post('/contacts', contactData);
			return response.data;
		} catch (error) {
			console.error('Error creating contact:', error);
			throw error;
		}
	}

	async updateContact(contactId, contactData) {
		try {
			const response = await this.api.put(
				`/contacts/${contactId}`,
				contactData,
			);
			return response.data;
		} catch (error) {
			console.error(`Error updating contact with ID ${contactId}:`, error);
			throw error;
		}
	}

	async deleteContact(contactId) {
		try {
			const response = await this.api.delete(`/contacts/${contactId}`);
			return response.data;
		} catch (error) {
			console.error(`Error deleting contact with ID ${contactId}:`, error);
			throw error;
		}
	}

	async getContactTypes() {
		try {
			const response = await this.api.get(`/contact-types`);
			return response.data;
		} catch (error) {
			console.error(`Error fetching contact types:`, error);
			throw error;
		}
	}

	async getGenders() {
		try {
			const response = await this.api.get(`/genders`);
			return response.data;
		} catch (error) {
			console.error(`Error fetching genders:`, error);
			throw error;
		}
	}

	async activeCall() {
		try {
			const response = await this.api.get(`/me/active-call`);
			return response.data;
		} catch (error) {
			console.error(`Error fetching contact types:`, error);
			throw error;
		}
	}

	async getPatient(id) {
		try {
			const response = await this.api.get(
				`crm-connections/nhds/external/patient/${id}`,
			);
			return response.data;
		} catch (error) {
			console.error(`Error fetching contact types:`, error);
			throw error;
		}
	}

	async getHistory() {
		try {
			const response = await this.api.get(`/me/call-history`);
			return response.data;
		} catch (error) {
			console.error(`Error fetching contact types:`, error);
			throw error;
		}
	}

	async uploadContacts(
		category,
		contactsData,
		cancelToken,
		onUploadProgress,
		onFinishedUploading,
	) {
		const contactsForm = new FormData();
		contactsForm.append('file', contactsData);

		try {
			const response = await this.api
				.post(`/contact-types/${category}/contacts`, contactsForm, {
					cancelToken: cancelToken,
					onUploadProgress: (progressEvent) =>
						onUploadProgress(progressEvent.loaded / progressEvent.total),
				})
				.then(onFinishedUploading());
			return response;
		} catch (error) {
			console.error('Error uploading contacts:', error);
			throw error;
		}
	}
}

export default new ContactsService();
