import { IdPrefixProvider } from '@fluentui/react-components';
import React, { useEffect } from 'react';
import { I18nextProvider } from 'react-i18next';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import i18n from './i18n';
import { setupInterceptor } from './interceptor/axios.interceptor';
import Layout from './layout/Layout';
import Calls from './pages/Calls';
import ContactInCall from './pages/ContactInCall';
import Contacts from './pages/Contacts';

const router = createBrowserRouter([
	{
		path: '/calls',
		element: <Layout />,
		children: [
			{ path: ':category?', index: true, element: <Calls /> }
		],
	},
	{
		path: '/contacts',
		element: <Layout />,
		children: [
			{ path: ':category?', index: true, element: <Contacts /> }
		],
	},
	{
		path: '/in-call',
		element: <Layout />,
		children: [
			{ index: true, element: <ContactInCall /> }
		]
	},
	{
		path: '*',
		element: <div>Not Found</div>,
	},
]);

export default function Root(props) {
	const { apiAddress, keycloak, correlationKey } = props;
	
	console.log('setting up interceptor apiAddress', apiAddress);
	setupInterceptor(apiAddress, keycloak.token, keycloak.tokenParsed, correlationKey);

	// useEffect(() => {
	// 	// Setup the interceptor with the token and correlationKey
	// 	console.log('setting up interceptor', apiAddress);
	// 	setupInterceptor(apiAddress, keycloak.token, keycloak.tokenParsed, correlationKey);
	//   }, [keycloak, correlationKey]);

	return (
		<IdPrefixProvider value="cc-contacts">
			<I18nextProvider i18n={i18n}>
				<RouterProvider router={router} />
			</I18nextProvider>
		</IdPrefixProvider>
	);
}
