import { Avatar, Body1, Button, Caption1, Dialog, DialogContent, DialogSurface, DialogTitle, makeStyles, tokens } from "@fluentui/react-components";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({

    root:{
        width: '550px',
        maxWidth: '100%',
    },

    title: {
        paddingBottom: tokens.spacingHorizontalL
    },

    content: {
        display: 'flex',
        flexDirection: 'row',
        gap: tokens.spacingHorizontalM,
        alignItems: 'center',
    },

    text: {
        display: 'flex',
        flexDirection: 'column',
    },

    actions: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: '20px',
        alignContent: 'stretch',
        gap: tokens.spacingHorizontalM,
        marginTop: tokens.spacingHorizontalL,
    },
});

const DeleteContactPopup = ({ contact, onConfirm, onCancel }) => {
    const classes = useStyles();
    const { t } = useTranslation('deleteContact');
    const open = contact !== null;

    const handleClose = () => {
        onCancel();
    };

    const onSubmit = () => {
        onConfirm(contact);
    };

    if(!contact) return null;

    return (
        <Dialog
            open={open}
            onOpenChange={handleClose}
            size="small">
                
			<DialogSurface className={classes.root}>
				<DialogTitle className={classes.title}>{t('title')}</DialogTitle>
				<DialogContent>

                    <div className={classes.content}>
                        
						<Avatar name={contact.name } size={40} />
                        <div className={classes.text}>
                            <Body1>{contact.name}</Body1>
                            <Caption1>{contact.email}</Caption1>
                        </div>
                    </div>


                    <div className={classes.actions}>
                        <Button appearance="secondary" onClick={handleClose}>
                            {t('cancel')}
                        </Button>
                        <Button appearance="primary" onClick={onSubmit}>
                            {t('confirm')}
                        </Button>

                    </div>
				</DialogContent>
			</DialogSurface>
        </Dialog>
    );
};

export default DeleteContactPopup;