import {
	Badge,
	Body1,
	Divider,
	makeStyles,
	Subtitle1,
	Text,
	tokens,
} from '@fluentui/react-components';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
	contactDataContainer: {
		padding: '20px',
		display: 'flex',
		width: '100%',
		flexDirection: 'column',
		rowGap: '20px',
	},
	section: {
		display: 'flex',
		flexDirection: 'column',
		padding: tokens.spacingVerticalS,
		rowGap: '10px',

		'> div': {
			display: 'flex',
			flexDirection: 'row',
			flexWrap: 'wrap',
			justifyContent: 'flex-start',
			alignItems: 'center',
			gap: '90px',
			padding: '10px 20px',
		},
	},
	divider: {
		flexGrow: 0,
	},

	box: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'left',
		columnGap: '5px',
	},

	label: {
		color: tokens.colorNeutralForeground4,
	},

	text: {
		color: tokens.colorNeutralForeground1,
		fontWeight: 600,
	},
});

const PlanStatusColors = {
	Active: 'success',
	Inactive: 'danger',
	Pending: 'warning',
};

const ContactInfo = ({ user, patient }) => {
	const { t } = useTranslation('contactInfo');
	const styles = useStyles();

	return (
		<div className={styles.contactDataContainer}>
			{/* Caller Info */}
			<div className={styles.section}>
				<Subtitle1>{t('callerInfo.title')}</Subtitle1>
				<div>
					<div className={styles.box}>
						<Body1 className={styles.label}>{t('callerInfo.name')}:</Body1>
						<Text className={styles.text}>
							{user.callingParty.displayName || '--'}
						</Text>
					</div>
					<div className={styles.box}>
						<Body1 className={styles.label}>
							{t('callerInfo.phoneNumber')}:
						</Body1>
						<Text className={styles.text}>
							{user.callingParty.phoneNumber || '--'}
						</Text>
					</div>
					<div className={styles.box}>
						<Body1 className={styles.label}>{t('callerInfo.nhsNumber')}:</Body1>
						<Text className={styles.text}>
							{patient?.nhsDetails?.nhsNumber || '--'}
						</Text>
					</div>
					<div className={styles.box}>
						<Body1 className={styles.label}>{t('callerInfo.practice')}:</Body1>
						<Text className={styles.text}>
							{patient?.practice?.name || '--'}
						</Text>
					</div>
				</div>
			</div>

			<Divider className={styles.divider} />

			{/* Plan Details */}
			<div className={styles.section}>
				<Subtitle1>{t('planDetails.title')}</Subtitle1>
				<div>
					<div className={styles.box}>
						<Body1 className={styles.label}>
							{t('planDetails.planStatus')}:
						</Body1>
						<Badge
							appearance="filled"
							color={PlanStatusColors[user.callingParty.planStatus]}
						>
							{user.callingParty.planStatus || '--'}
						</Badge>
					</div>
					<div className={styles.box}>
						<Body1 className={styles.label}>
							{t('planDetails.subscriptionPlan')}:
						</Body1>
						<Text className={styles.text}>
							{user.callingParty.subscriptionPlan || '--'}
						</Text>
					</div>
					<div className={styles.box}>
						<Body1 className={styles.label}>
							{t('planDetails.validUntil')}:
						</Body1>
						<Text className={styles.text}>
							{user.callingParty.validUntil || '--'}
						</Text>
					</div>
				</div>
			</div>

			<Divider className={styles.divider} />

			{/* Appointment History */}
			<div className={styles.section}>
				<Subtitle1>{t('appointmentHistory.title')}</Subtitle1>
				<div>
					<div className={styles.box}>
						<Body1 className={styles.label}>
							{t('appointmentHistory.recall')}:
						</Body1>
						<Text className={styles.text}>
							{user.callingParty.recall || '--'}
						</Text>
					</div>
					<div className={styles.box}>
						<Body1 className={styles.label}>
							{t('appointmentHistory.upcoming')}:
						</Body1>
						<Text className={styles.text}>
							{user.callingParty.upcoming || '--'}
						</Text>
					</div>
					<div className={styles.box}>
						<Body1 className={styles.label}>
							{t('appointmentHistory.past')}:
						</Body1>
						<Text className={styles.text}>
							{user.callingParty.past || '--'}
						</Text>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ContactInfo;
