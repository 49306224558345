import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import commonEN from './locales/en/common.json';
import contactInfoEN from './locales/en/contact-info.json';
import contactEN from './locales/en/contact.json';
import deleteContactEN from './locales/en/delete-contact.json';
import editContactEN from './locales/en/edit-contact.json';
import searchEN from './locales/en/search.json';

const resources = {
  en: {
    search: searchEN,
    contact: contactEN,
    editContact: editContactEN,
    deleteContact: deleteContactEN,
    contactInfo: contactInfoEN,
    common: commonEN,
  }
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'en',
  fallbackLng: 'en',
  ns: ['search', 'contact', 'editContact', 'deleteContact', 'contactInfo', 'common'],
  defaultNS: ['common'],
  fallbackNS: 'common',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
