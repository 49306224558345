
// ContactsFilter.jsx
import { Stack, StackItem } from '@fluentui/react';
import {
    makeStyles,
    SkeletonItem
} from '@fluentui/react-components';


const skeletonStyle = makeStyles({
	firstRow: {
		alignItems: 'center',
		display: 'flex',
		boxSizing: 'border-box',
		gap: '10px',
	},
	circle: {
		flex: '0 0 40px',
	},
});

const SkeletonLoader = () => {
	const styles = skeletonStyle();
	return (
		<Stack tokens={{ childrenGap: 10 }}>
			<StackItem className={styles.firstRow}>
				<SkeletonItem size={16} style={{ flexGrow: 1 }} />
			</StackItem>
			{[...Array(5)].map((_, index) => (
				<StackItem key={index} className={styles.firstRow}>
					<SkeletonItem shape="circle" size={40} className={styles.circle} />
					<SkeletonItem size={16} style={{ flexGrow: 1 }} />
				</StackItem>
			))}
		</Stack>
	);
};

export default SkeletonLoader;