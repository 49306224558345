import { Stack } from '@fluentui/react';
import {
	Button,
	Dialog,
	DialogContent,
	DialogSurface,
	DialogTitle,
	Field,
	Input,
	makeStyles,
	Select,
	tokens
} from '@fluentui/react-components';
import { DatePicker } from '@fluentui/react-datepicker-compat';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { parseISO, format } from 'date-fns';
import * as yup from 'yup';
import contactsService from '../../services/contactsService';
import useToast from '../../theme/toast';
import InputMask from 'react-input-mask';

// Use a default mask if not provided via environment variables
const phoneMask = '+99 999 9999-9999';

const useStyles = makeStyles({

    root:{
        width: '350px',
        maxWidth: '100%',

    },

    title: {
        paddingBottom: tokens.spacingHorizontalM
    },

    actions: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '20px',
        alignContent: 'stretch',
        gap: tokens.spacingHorizontalM,

        '> button': {
            width: '100%',
        },
    },
});


const ContactEditPopup = ({ onClose, contact, contactTypes }) => {
    const toast = useToast();
	const [open, setOpen] = useState(false);
	const [genders, setGenders] = useState([]);
	const { t } = useTranslation('editContact');
    const classes = useStyles();
	const schema = yup.object().shape({
		firstName: yup.string().required(t('validation.firstNameRequired')),
		lastName: yup.string().required(t('validation.lastNameRequired')),	
		gender: yup.string().required(t('validation.genderRequired')),
		phoneNumber: yup.string()
			.required(t('validation.phoneRequired'))
			.test('len', t('validation.invalidPhone'), val => {
				const cleaned = val.replace(/[^0-9]/g, '');
				const maskDigits = phoneMask.replace(/[^0-9]/g, '').length;
				return cleaned.length === maskDigits;
			}),
		email: yup.string().required(t('validation.emailRequired')).email(t('validation.invalidEmail')),
		type: yup.string().required(t('validation.categoryRequired')),
		dob: yup
			.date()
			.required(t('validation.birthdayRequired'))
			.typeError(t('validation.invalidDate')),
	});

	const defaultValues = {
		firstName: contact?.firstName || '',
		lastName: contact?.lastName || '',
		gender: contact?.gender || '',
		phoneNumber: contact?.phoneNumber || '',
		email: contact?.email || '',
		category: contact?.category || '',
		dob: contact?.dob || '',
	};

	const {
		handleSubmit,
		control,
		reset,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
		defaultValues: defaultValues,
	});

	const onSubmit = async (data) => {

		try {			
			if(contact?.id) {
				await contactsService.updateContact(contact.id, data);
			} else {
				await contactsService.createContact(data);
			}
			
			reset();
			setOpen(false);
			onClose(true);

		} catch (error) {
			toast.error({ message: 'Failed to save contact'});
		}
			
	};

	const handleClose = () => {
		reset();
		onClose(false);
		setOpen(false);
	};

	const initializecontact = (contact) => {

		return {...contact,
			phoneNumber: contact.phoneNumber?.replace(/[^0-9]/g, '') || '',
			dob: contact.dob ? parseISO(contact.dob) : '',
			type: contact.type || '',
			gender: contact.gender?.name || ''
		};

	};

	useEffect(() => {
		const fetchGenders = async () => {
			try {
				const types = await contactsService.getGenders();
				setGenders(types);
			} catch (error) {
				toast.error({title: `tes`, message: 'Failed to fetch genders'});
			}
		};

		fetchGenders();
	}, []);

	useEffect(() => {
		reset();
		if (contact) {
			const c = initializecontact(contact);
			console.log('reseting contact', c);
			reset(c);
			setOpen(true);
		}
	}, [contact, reset, setOpen]);

	const onFormatDate = (date) => {
	  return !date
		? ""
		: date.toLocaleDateString();
	};

	const title = contact?.id ? t('editTitle') : t('addTitle');

	return (
		<Dialog
			open={open}
			onOpenChange={handleClose}
		>
			<DialogSurface className={classes.root}>
				<DialogTitle className={classes.title}>{title}</DialogTitle>
				<DialogContent >
					<form onSubmit={handleSubmit(onSubmit)}>
						<Stack tokens={{ childrenGap: 12 }}>
							<Field
								label={t('firstName')}
								validationMessage={errors.firstName?.message}
							>
								<Controller
									name="firstName"
									control={control}
									render={({ field: { onChange, onBlur, value } }) => 
										<Input placeholder={t('placeholder.firstName')} type="text" 
												onBlur={onBlur}
												onChange={onChange}
												value={value} />}
								/>
							</Field>
							<Field
								label={t('lastName')}
								validationMessage={errors.lastName?.message}
							>
								<Controller
									name="lastName"
									control={control}
									render={({ field: { onChange, onBlur, value } }) => 
										<Input placeholder={t('placeholder.lastName')} type="text" 
												onBlur={onBlur}
												onChange={onChange}
												value={value} />}
								/>
							</Field>

							<Field
								label={t('gender')}
								validationMessage={errors.gender?.message}
							>
								<Controller
									name="gender"
									control={control}
									render={({ field }) => (
										<Select {...field}>
                                            <option value="">{t('placeholder.gender')}</option>
											{genders.map((g)=> 
												<option key={g.name} value={g.name}>{g.title}</option>
											)}
										</Select>
									)}
								/>
							</Field>

							<Field
								label={t('birthday')}
								validationMessage={errors.dob?.message}
							>
								<Controller
									name="dob"
									control={control}
									render={({ field }) => 
									<DatePicker placeholder={t('placeholder.birthday')}		
									{...field}
											formatDate={onFormatDate}
											onSelectDate={(d) => field.onChange(d)}	 />}
								/>
								
							</Field>
                            
							
							<Field
								label={t('phone')}
								validationMessage={errors.phone?.message}
							>
								<Controller
									name="phoneNumber"
									control={control}
									render={({ field }) => (
										<InputMask mask={phoneMask} {...field}>
											{(inputProps) => (
												<Input {...inputProps} ref={field.inputRef} 
												autoFocus placeholder={phoneMask}
												
													/>
											)}
										</InputMask>
									)}
								/>
							</Field>

							<Field
								label={t('email')}
								validationMessage={errors.email?.message}
							>
								<Controller
									name="email"
									control={control}
									render={({ field }) => <Input placeholder={t('placeholder.email')} type="text" {...field} />}
								/>
							</Field>

							<Field
								label={t('category')}
								validationMessage={errors.category?.message}
							>
								<Controller
									name="type"
									control={control}
									render={({ field }) => (

										<Select {...field}>
                                            <option value="">{t('placeholder.category')}</option>
											{contactTypes.map((category) => (
												<option key={category.name} value={category.name}>{category.description}</option>
											))}
										</Select>

									)}
									
								/>
							</Field>

						</Stack>
					</form>
                    <div className={classes.actions}>
                        <Button appearance="secondary" onClick={handleClose}>
                            {t('cancel')}
                        </Button>
                        <Button appearance="primary" onClick={handleSubmit(onSubmit)}>
                            {t('save')}
                        </Button>

                    </div>
				</DialogContent>
			</DialogSurface>
		</Dialog>
	);
};

export default ContactEditPopup;
