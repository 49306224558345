// ContactsFilter.jsx
import { Divider, Input, makeStyles, tokens } from '@fluentui/react-components';
import { Search20Regular } from '@fluentui/react-icons';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import contactsService from '../../services/contactsService';
import ContactSortPopupMenu from '../ContactSortPopupMenu';
import ContactsList from './ContactsList';
import EmptyContacts from './EmptyContacts';
import SkeletonLoader from './SkeletonLoader';

const useStyles = makeStyles({
	filterBar: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end',
		padding: tokens.spacingHorizontalS,
	},
});

const ContactsFilter = ({
	category,
	contactTypes,
	onAddClick,
	onEditClick,
	onDeleteClick,
	onWhatsappClick,
	onChatClick,
	onCallClick,
	refresh,
}) => {
	const style = useStyles();
	const { t } = useTranslation('search');
	const [sortBy, setSortBy] = useState('firstName');
	const [sortDirection, setSortDirection] = useState('Ascending');
	const [noContacts, setNoContacts] = useState(true);
	const [contacts, setContacts] = useState([]);
	const [filteredContacts, setFilteredContacts] = useState([]);
	const [loading, setLoading] = useState(true);
	const [searchTerm, setSearchTerm] = useState('');

	useEffect(() => {
		fetchContacts();
	}, [refresh, category, sortBy, sortDirection]);

	useEffect(() => {
		setFilteredContacts(
			contacts.filter(
				(contact) =>
					contact.firstName
						?.toLowerCase()
						?.includes(searchTerm.toLowerCase()) ||
					contact.lastName?.toLowerCase()?.includes(searchTerm.toLowerCase()),
			),
		);
	}, [searchTerm, contacts]);

	const fetchContacts = async () => {
		setLoading(true);

		try {
			let data = [];

			const direction = sortBy === 'dob' ? sortDirection === 'Ascending' ? 'Descending' : 'Ascending' : sortDirection;

			if (category === '') {
				data = await contactsService.getContacts(
					undefined,
					sortBy,
					direction,
				);
			} else {
				data = await contactsService.getContacts(
					category,
					sortBy,
					direction,
				);
			}

			console.log(`fetching contacts for ${category}`, data);

			if (noContacts && data.length > 0) {
				setNoContacts(false);
			}

			data = data.map((contact) => {
				contact.name = `${contact.firstName} ${contact.lastName}`;
				return contact;
			});

			setContacts(data);
			setFilteredContacts(data);
		} catch (error) {
			console.error('Error fetching contacts:', error);
		} finally {
			setLoading(false);
		}
	};

	const handleOrderChange = (sortBy, sortDirection) => {
		setSortBy(sortBy);
		setSortDirection(sortDirection);
	};

	const handleAddContact = () => {
		if (onAddClick) {
			onAddClick();
		}
	};

	const fields = {
		firstName: 'Name',
		dob: 'Age',
		gender: 'Gender',
		recentlyAdded: 'Recently added',
	};

	if(loading)
		return <SkeletonLoader />;

	return noContacts ? (
		<EmptyContacts onAddClick={handleAddContact} />
	) : (
		<>
			<div className={style.filterBar}>
				<ContactSortPopupMenu sort={sortBy} direction={sortDirection} fields={fields} onChange={handleOrderChange} />

				<Input
					appearance="filled-darker"
					placeholder={t('search')}
					contentBefore={<Search20Regular />}
					onChange={(e, newValue) => setSearchTerm(newValue.value || '')}
					styles={{ root: { marginBottom: 20 } }}
				/>
			</div>
			<Divider />
			<div
				style={{
					padding: tokens.spacingHorizontalXL,
					paddingTop: tokens.spacingVerticalXXL,
				}}
			>
				<ContactsList
					contacts={filteredContacts}
					onEditClick={onEditClick}
					onDeleteClick={onDeleteClick}
					onWhatsappClick={onWhatsappClick}
					onChatClick={onChatClick}
					onCallClick={onCallClick}
				/>
			</div>
		</>
	);
};

export default ContactsFilter;
