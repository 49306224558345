// ContactsFilter.jsx
import { TextField } from '@fluentui/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import contactsService from '../../services/contactsService';
import ContactsShortenList from './ContactsShortenList';
import SkeletonLoader from './SkeletonLoader';
import useToast from '../../theme/toast';

const ContactsShortenFilter = ({ category }) => {
	const { t } = useTranslation('search');
	const toast = useToast();
	const [contacts, setContacts] = useState([]);
	const [filteredContacts, setFilteredContacts] = useState([]);
	const [loading, setLoading] = useState(true);
	const [searchTerm, setSearchTerm] = useState('');

	useEffect(() => {
		const fetchContacts = async () => {
			if (!category) return;
			setLoading(true);
			try {
				const response = await contactsService.getContacts(category);
				setContacts(response);
				setFilteredContacts(response);
			} catch (error) {
				toast.error({ message: 'Error fetching contacts:' });
			} finally {
				setLoading(false);
			}
		};

		fetchContacts();
	}, [category]);

	useEffect(() => {
		setFilteredContacts(
			contacts.filter((contact) =>
				contact.displayName.toLowerCase().includes(searchTerm.toLowerCase()),
			),
		);
	}, [searchTerm, contacts]);

	return (
		<div>
			{loading ? (
				<SkeletonLoader />
			) : (
				<>
					<TextField
						placeholder={t('searchByName')}
						onChange={(e, newValue) => setSearchTerm(newValue || '')}
						styles={{ root: { marginBottom: 20 } }}
					/>
					<ContactsShortenList contacts={filteredContacts} />
				</>
			)}
		</div>
	);
};

export default ContactsShortenFilter;
