// ContactShortenItem.jsx
import { Avatar, Button } from '@fluentui/react-components';
import { Call32Regular, Video32Regular } from '@fluentui/react-icons';
import React from 'react';

const ContactShortenItem = ({ contact }) => {
	return (
		<div
			style={{
				display: 'flex',
				alignItems: 'center',
				marginBottom: 10,
				gap: 7,
			}}
		>
			<Avatar name={contact.displayName} size={32} />
			<div style={{ flexGrow: 1 }}>
				<div>{contact.displayName}</div>
			</div>
			<Button
				appearance="transparent"
				icon={<Video32Regular />}
				iconProps={{ iconName: 'Phone' }}
				title="Call"
				onClick={() => console.log(`Dialing ${contact.phone}`)} // Implement dialing functionality
			/>
			<Button
				appearance="transparent"
				icon={<Call32Regular />}
				iconProps={{ iconName: 'Phone' }}
				title="Call"
				onClick={() => console.log(`Dialing ${contact.phone}`)} // Implement dialing functionality
			/>
		</div>
	);
};

export default ContactShortenItem;
